<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1" style="flex-wrap:wrap;">
        <el-input size="small" v-model="queryForm.keyword" placeholder="请输入企业名称或联系人名称" style="width:200px;"></el-input>
        <el-date-picker
          size="small"
          v-model="queryForm.year"
          type="year"
          value-format="yyyy"
          placeholder="选择年份" style="margin-left: 10px">
        </el-date-picker>

        <el-button class="ml10" size="small" type="primary" icon="el-icon-search"
          @click="() => { page.current_page = 1; getList() }">搜索</el-button>


        <el-button class="ml10" size="small" type="primary" icon="el-icon-notebook-2" @click="downXls">导出XLS</el-button>

      </div>


    </el-row>
    <div class="dividerBar"></div>
    <el-table :data="tableData" row-key="fair_id" height="100%" size="small" stripe border
      style="width:fit-content;width:100%;">
      <el-table-column type="index" label="序号" width="55px" align="center"></el-table-column>
      <el-table-column label="企业名称" prop="firm_name" min-width="150px" align="center"></el-table-column>
      <el-table-column label="企业联系人" prop="firm_principal" width="120px" align="center"></el-table-column>
      <el-table-column label="联系人电话" prop="tel" width="150px"></el-table-column>
      <el-table-column label="是否参加宣讲会" prop="id" width="150px" align="center">
        <template slot-scope="scope">{{ scope.row.pjson.xjh }}</template>
      </el-table-column>
      <el-table-column label="企业地址" prop="address" min-width="200px" align="center"></el-table-column>
      <el-table-column label="岗位数" prop="fair_id" width="100px" align="center">
        <template slot-scope="scope">{{ scope.row.job_info_list.length }}</template>
      </el-table-column>
      <el-table-column label="登记时间" prop="lasttime" width="200px" align="center"></el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-tag class="pointer ml10" type="primary" size="mini" @click="editItem(scope.row)">查看</el-tag>
          <el-tag class="pointer ml10" type="danger" size="mini" @click="handleDelete(scope.row)">删除</el-tag>

        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>

    <el-dialog class="cus_dialog" ref="ruleForm" title="企业登记详细" :visible.sync="dialogShow" :close-on-click-modal="false"
      width="60%">
      <table class="dttable" v-if="dialogForm">
        <tr>
          <td class="tdheadersml">企业名称</td>
          <td style="width: 200px;">
            <el-input size="small" v-model="dialogForm.firm_name"></el-input>
          </td>
          <td class="tdheadersml">统一信用代码</td>
          <td style="width: 200px;">{{ dialogForm.firm_num }}</td>
        </tr>
        <tr>
          <td class="tdheadersml">企业类型</td>
          <td style="width: 200px;">{{ dialogForm.enter_type }}</td>
          <td class="tdheadersml">所属行业</td>
          <td style="width: 200px;">{{ dialogForm.tyc_json.industry }}</td>
        </tr>
        <tr>
          <td class="tdheadersml">注册资本</td>
          <td style="width: 200px;">{{ dialogForm.tyc_json.regCapital }}</td>
          <td class="tdheadersml">企业状态</td>
          <td style="width: 200px;">{{ dialogForm.tyc_json.regStatus }}</td>
        </tr>
        <tr>
          <td class="tdheadersml">企业规模</td>
          <td style="width: 200px;">{{ dialogForm.tyc_json.staffNumRange }}</td>
          <td class="tdheadersml">所在地区</td>
          <td style="width: 200px;">{{ dialogForm.tyc_json.city }}{{ dialogForm.tyc_json.district }}</td>
        </tr>
        <tr>
          <td class="tdheadersml">企业注册地址</td>
          <td colspan="3">{{ dialogForm.tyc_json.regLocation }}</td>

        </tr>
        <tr>
          <td class="tdheadersml">工作所在地址</td>
          <td colspan="3"><el-input size="small" v-model="dialogForm.address"></el-input></td>

        </tr>
        <tr>
          <td class="tdheadersml">企业联系人</td>
          <td style="width: 200px;"><el-input size="small" v-model="dialogForm.firm_principal"></el-input></td>
          <td class="tdheadersml">联系人电话</td>
          <td style="width: 200px;"><el-input size="small" v-model="dialogForm.tel"></el-input> </td>
        </tr>
        <tr>
          <td class="tdheadersml">是否参加宣讲会</td>
          <td style="width: 200px;"><el-radio-group v-model="dialogForm.pjson.xjh">
              <el-radio label="参加">参加</el-radio>
              <el-radio label="不参加">不参加</el-radio>
              <el-radio label="不确定">不确定</el-radio>
            </el-radio-group>
          </td>
          <td class="tdheadersml">登记岗位数</td>
          <td style="width: 200px;">{{ dialogForm.job_info_list.length }}</td>
        </tr>
      </table>
      <table class="dttable" v-if="dialogForm" style="text-align: center;">
        <tr>

          <td colspan=6 style="text-align: center;">
            登记岗位信息
            <i class="el-icon-plus" style="cursor: pointer; color: skyblue; float: right;font-size: 20px;" title="添加岗位"
              @click="addJob"></i>
          </td>


        </tr>
        <tr>

          <td style="width: 16.6%;">岗位名称</td>
          <td style="width: 16.6%;">专业层次</td>
          <td style="width: 16.6%;">男生人数</td>
          <td style="width: 16.6%;">女生人数</td>
          <td style="width: 16.6%;">实习津贴</td>
          <td style="width: 16.6%;">定岗工资</td>

        </tr>

        <tr v-for="(job, i) in dialogForm.job_info_list" :key="i">
          <td><el-input size="small" v-model="job.post"></el-input></td>
          <td><el-input size="small" v-model="job.culture"></el-input></td>
          <td><el-input size="small" v-model="job.boy"></el-input></td>
          <td><el-input size="small" v-model="job.girl"></el-input></td>
          <td><el-input size="small" v-model="job.jintie"></el-input></td>
          <td style="position: relative;"><el-input size="small" v-model="job.salary">


            </el-input>
            <div style=" position: absolute; top: 15px;right: -10px;">
              <i class="el-icon-remove-outline" @click="removeJob(i)"
                style="cursor: pointer; color: orangered; float: right;font-size: 20px;" title="删除岗位"></i>
            </div>

          </td>

        </tr>
      </table>

      <table class="dttable" v-if="dialogForm && pmid == 216">
        <tr>
          <td class="tdheadersml">上传图片</td>
          <td style="">
            <div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
              v-for="(  img, idx  ) in   fileList  " :key="idx">
              <el-image style="width: 100px; height: 100px" :src="img.fileUrl || img.src"
                :preview-src-list="[img.fileUrl || img.src]" img="cover"></el-image>

              <div class="imgremove" @click="removeImg(idx)"><i class="el-icon-remove"></i></div>
            </div>
            <div style="display:inline-block;vertical-align: top;">
              <el-upload style="display: inline-block; margin-right: 20px" action="" :http-request="uploadOss"
                :on-success="uploadFiles" :show-file-list="false" name="image" accept="image/png, image/jpeg">
                <div class="imgaddbtn">
                  ＋
                </div>
              </el-upload>
            </div>
          </td>
        </tr>
      </table>
      <div style="text-align: right;">
        <el-button type="primary" @click="saveForm" icon="el-icon-check"
          style=" margin-top: 50px; margin-bottom: 50px;">保存</el-button>
      </div>



    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dateArray: [],
      tableData: [],
      MsgData: [],
      queryForm: {
        keyword: ""
      },
      dialogShow: false,
      dialogForm: null,
      page: {
        count: 0,
        current_page: 1,
        per_page: 20,
        total_page: 0
      },
      pmid: 0,
      fileList: [],

    };
  },

  mounted() {
    this.pmid = window.pmid
    this.getList();
  },
  methods: {
    getList() {
      this.fileList = []
      this.$http
        .post("/api/xysxh_list", {
          page: this.page.current_page,
          pagesize: this.page.per_page,
          keyword: this.queryForm.keyword,
          year: this.queryForm.year

        })
        .then(res => {
          for (let item of res.data.data) {
            if (item.job_info_list) {
              item.job_info_list = JSON.parse(item.job_info_list)
            } else {
              item.job_info_list = []
            }
            if (item.pjson) {
              item.pjson = JSON.parse(item.pjson)
            } else {
              item.pjson = {}
            }

            if (item.tyc_json) {
              item.tyc_json = JSON.parse(item.tyc_json)
            } else {
              item.tyc_json = JSON.parse(item.search_json)
            }

          }
          this.tableData = res.data.data;
          this.page = res.data.page;
        });
    },
    saveEnterNote() {
      this.$router.push("/qytz_edit");
    },
    editItem(item) {
      this.dialogForm = item
      if (item.files) {
        this.fileList = JSON.parse(item.files)
      } else {
        this.fileList = []
      }
      this.dialogShow = true

    },

    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList()
    },
    downXls() {
      this.$http.post("/api/xysxh_downxls", this.queryForm).then(res => {
        if (res.data.url) {
          window.open(res.data.url, "_blank")
        }
      })
    },
    addJob() {
      this.dialogForm.job_info_list.push({ "post": "", "num": 3, boy: "", girl: "", "specialty": "", "culture": "", "foreignLanguage": "", "computer": "", jintie: "", salary: "" })
      
    },
    removeJob(i) {
      this.dialogForm.job_info_list.splice(i, 1)
    },
    uploadFiles(e) {
      this.fileList.push(e)
    },
    removeImg(idx) {
      this.fileList.splice(idx, 1)
    },
    saveForm(){
      //检查岗位有没有填写
      let jobok = false
      for (let job of this.dialogForm.job_info_list) {

        if (!job.post || !job.culture || !job.jintie || !job.salary) {
          this.$message({
            message: "请完善岗位信息",
            type: 'error',
            customClass: "toast",
            duration: 2000
          });
          return
        }
      }
      this.dialogForm.files = JSON.stringify(this.fileList)
      this.$http.post("/api/enterprise_dengji_edit", this.dialogForm).then(res => {
        this.$message({
          message: "修改成功！",
          type: 'success',
          customClass: "toast",
          duration: 2000
        });
        this.dialogShow = false
        this.getList()

      })
    },
    handleDelete(row) {
      this.$confirm('此操作不可恢复，确定删除？', {
        type: "warning"
      }).then(res => {
        this.$http.post('/api/zp_job_fair_delete',{id:row.fair_id}).then(res => {
          this.$message.success('删除成功')
          this.getList()
        })
      })
    },

  }
};
</script>
<style scoped>
/deep/.headbar {
  height: 10px;
}

.imgaddbtn {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dotted #ccc;
  font-size: 30px;
  cursor: pointer;
}

.imgremove {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;

  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
  color: #ccc;
}

.imgremove:hover {
  color: orange;
}
</style>
